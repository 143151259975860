export default {
  state () {
    return {
      questionnaire: ''
    }
  },
  getters: {
    questionnaire: state => state.questionnaire ? 'Premium ' + state.questionnaire + ' Raporu' : ''
  },
  actions: {
    changeQuestionnaire: (context, questionnaire) => context.commit('changeQuestionnaire', questionnaire)
  },
  mutations: {
    changeQuestionnaire: (state, questionnaire) => {
      state.questionnaire = questionnaire
      return state.questionnaire
    }
  },
  namespaced: true
}
