export default {
  path: '/sozlesmeler',
  name: 'Contracts',
  meta: { layout: 'SidebarLayout' },
  component: () => import(/* webpackChunkName: "contract" */ '@/views/Public/ContractView'),
  redirect: { name: 'Kvkk' },
  children: [
    {
      path: 'kvkk',
      name: 'Kvkk',
      component: () => import(/* webpackChunkName: "contract" */ '@/views/Contracts/KvkkView')
    },
    {
      path: 'uyelik-sozlesmesi',
      name: 'Terms',
      component: () => import(/* webpackChunkName: "contract" */ '@/views/Contracts/TermsView')
    },
    {
      path: 'kullanim-kosullari',
      name: 'Conditions',
      component: () => import(/* webpackChunkName: "contract" */ '@/views/Contracts/ConditionsView')
    },
    {
      path: 'gizlilik-politikamiz',
      name: 'Privacy',
      component: () => import(/* webpackChunkName: "contract" */ '@/views/Contracts/PrivacyView')
    },
    {
      path: 'cerez-politikamiz',
      name: 'Cookie',
      component: () => import(/* webpackChunkName: "contract" */ '@/views/Contracts/CookieView')
    },
    {
      path: 'aydinlatma-metni',
      name: 'Clarification',
      component: () => import(/* webpackChunkName: "contract" */ '@/views/Contracts/ClarificationView')
    },
    {
      path: 'basvuru-yonergesi',
      name: 'Application',
      component: () => import(/* webpackChunkName: "contract" */ '@/views/Contracts/ApplicationView')
    },
    {
      path: 'acik-riza-metni',
      name: 'Consent',
      component: () => import(/* webpackChunkName: "contract" */ '@/views/Contracts/ConsentView')
    }
  ]
}
