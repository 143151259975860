import { createApp, h, provide } from 'vue'
import App from '@/App.vue'
import router from '@/router'
import store from '@/store'
import { DefaultApolloClient } from '@vue/apollo-composable'
import VeeValidatePlugin from '@/includes/validation'
import apolloClient from '@/includes/apollo'
import VueGtag from 'vue-gtag'
import globalDirectives from './directives'
import VueCookieComply from 'vue-cookie-comply'
import VueApexCharts from 'vue3-apexcharts'

import '@/assets/scss/main.scss'
import 'vue-cookie-comply/dist/style.css'

const VueGtagOptions = {
  config: { id: 'G-LNPN6XG9BZ' },
  enabled: true
}

createApp({
  setup () {
    provide(DefaultApolloClient, apolloClient)
  },
  render: () => h(App)
})
  .use(store)
  .use(router)
  .use(VeeValidatePlugin)
  .use(VueCookieComply)
  .use(VueApexCharts)
  .use(VueGtag, VueGtagOptions, router)
  .use(globalDirectives)
  .mount('#app')
