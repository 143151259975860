import { createRouter, createWebHistory } from 'vue-router'
import AuthRoutes from '@/router/auth'
import DashboardRoutes from '@/router/dashboard'
import PublicRoutes from '@/router/public'
import CartRoutes from '@/router/cart'
import ContractRoutes from '@/router/contract'
import store from '@/store/index'
import metaTags from '@/router/metaTags.json'
// import { loading } from '@/includes/loader'

const routes = [
  AuthRoutes,
  DashboardRoutes,
  ...CartRoutes,
  ContractRoutes,
  ...PublicRoutes
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    }

    return { left: 0, top: 0 }
  }
})
const addLinkTag = (name, href) => {
  const oldLink = document.getElementsByTagName('link')
  for (let i = 0; i < oldLink.length; i++) {
    if (oldLink[i].rel === name) {
      oldLink[i].remove()
    }
  }
  const link = document.createElement('link')
  link.setAttribute('rel', name)
  link.setAttribute('href', href)
  document.head.appendChild(link)
}
const addMetaTag = (name, content, property = false) => {
  const oldMeta = document.getElementsByTagName('meta')
  for (let i = 0; i < oldMeta.length; i++) {
    if (oldMeta[i].name === name) {
      oldMeta[i].remove()
    }
    if (oldMeta[i].getAttribute('property') === name) {
      oldMeta[i].remove()
    }
  }
  const meta = document.createElement('meta')
  if (property) {
    meta.setAttribute('property', name)
  } else {
    meta.name = name
  }
  meta.content = content
  document.head.appendChild(meta)
}
const standardTags = [
  { 'og:locale': 'tr_TR' },
  { 'og:type': 'website' },
  { 'og:site_name': process.env.VUE_APP_NAME },
  { 'twitter:card': 'summary_large_image' },
  { 'twitter:site': '@meslek360' },
  { 'twitter:creator': '@meslek360' }
]
const requiredTags = ['og:title', 'og:description', 'twitter:title', 'twitter:description']
const addStandardTags = (to) => {
  standardTags.forEach(tag => addMetaTag(Object.keys(tag)[0], tag[Object.keys(tag)[0]], true))
  addMetaTag('og:url', 'https://www.meslek360.com' + to.fullPath)
  addLinkTag('canonical', 'https://www.meslek360.com' + to.fullPath)
}
router.beforeEach(function (to, from, next) {
  if (to.name === 'notFound') {
    addMetaTag('robots', 'noindex')
  }
  if (Object.prototype.hasOwnProperty.call(metaTags, to.name)) {
    let description
    if (Object.keys(to.params).length) {
      const key = Object.keys(to.params)[0]
      description = metaTags[to.name][to.params[key]].metaTags.filter(x => x.name === 'description')[0].content
      document.title = `${process.env.VUE_APP_NAME} | ${metaTags[to.name][to.params[key]].title}`
      metaTags[to.name][to.params[key]].metaTags.forEach(tag => Object.prototype.hasOwnProperty.call(tag, 'name') ? addMetaTag(tag.name, tag.content) : addMetaTag(tag.property, tag.content, true))
      requiredTags.filter(tag => metaTags[to.name][to.params[key]].metaTags[tag] !== tag)
    } else {
      document.title = `${process.env.VUE_APP_NAME} | ${metaTags[to.name].title}`
      description = metaTags[to.name].metaTags.filter(x => x.name === 'description')[0].content
      metaTags[to.name].metaTags.forEach(tag => Object.prototype.hasOwnProperty.call(tag, 'name') ? addMetaTag(tag.name, tag.content) : addMetaTag(tag.property, tag.content, true))
      requiredTags.filter(tag => metaTags[to.name].metaTags[tag] !== tag)
    }

    if (requiredTags.length) {
      requiredTags.forEach(tag => addMetaTag(tag, tag.includes('title') ? document.title : description))
    }
  }
  addStandardTags(to)
  if (to.name) {
    // loading.show()
  }
  if (to.meta.requiresAuth && !store.getters['auth/isAuthenticated']) {
    next({ name: 'Login', query: { redirect: to.path.split('/').filter(value => value !== '').join('/') } })
  } else {
    next()
  }
})

router.afterEach(() => {
  // loading.hide()
})

router.onError((error, to, from) => {
  console.log(error)
  const pattern = /Loading chunk (\d)+ failed/g
  const isChunkLoadFailed = error.message.match(pattern)
  const targetPath = router.history.pending.fullPath
  if (isChunkLoadFailed) {
    router.replace(targetPath)
  }
})

export default router
