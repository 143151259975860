import { createStore } from 'vuex'
import authModule from './auth/index'
import addressModule from './address/index'
import questionnaireModule from './questionnaire/index'
import cartModule from './cart/index'
import productModule from './product/index'
import notificationModule from './notification/index'
import { toRaw } from 'vue'

export default createStore({
  modules: {
    auth: authModule,
    address: addressModule,
    questionnaire: questionnaireModule,
    cart: cartModule,
    product: productModule,
    notification: notificationModule
  },
  state: {
    isModalVisible: false,
    barNav: false,
    overlay: false,
    menu: false,
    registerEmail: '',
    universityYears: [],
    valuesUnlockRemain: 3,
    valuesUnlocked: [],
    carrierUnlockRemain: 3,
    carrierUnlocked: []
  },
  getters: {
    isModalVisible: (state) => state.isModalVisible,
    barNav: (state) => state.barNav,
    overlay: (state) => state.overlay,
    menu: (state) => state.menu,
    registerEmail: (state) => state.registerEmail,
    universityYears: (state) => state.universityYears,
    valuesUnlockRemain: (state) => state.valuesUnlockRemain,
    valuesUnlocked: (state) => state.valuesUnlocked,
    carrierUnlockRemain: (state) => state.carrierUnlockRemain,
    carrierUnlocked: (state) => state.carrierUnlocked
  },
  mutations: {
    showModal: (state) => {
      state.isModalVisible = true
    },
    closeModal: (state) => {
      state.isModalVisible = false
    },
    showBarNav: (state) => {
      state.barNav = true
    },
    closeBarNav: (state) => {
      state.barNav = false
    },
    showOverlay: (state) => {
      state.overlay = true
    },
    closeOverlay: (state) => {
      state.overlay = false
    },
    closeMenu: (state) => {
      state.menu = false
    },
    registerEmail: (state, payload) => {
      state.registerEmail = payload
    },
    universityYears: (state, payload) => {
      state.universityYears = payload
    },
    valuesUnlockRemain: (state, payload) => {
      state.valuesUnlockRemain = payload
    },
    valuesUnlocked: (state, payload) => {
      payload.id = parseInt(payload.id)
      const item = state.valuesUnlocked.find(el => {
        el = toRaw(el)
        return el.id === payload.id
      })
      if (item) {
        return
      }
      state.valuesUnlocked.push(payload)
    },
    carrierUnlockRemain: (state, payload) => {
      state.carrierUnlockRemain = payload
    },
    carrierUnlocked: (state, payload) => {
      payload.id = parseInt(payload.id)
      const item = state.carrierUnlocked.find(el => {
        el = toRaw(el)
        return el.id === payload.id
      })
      if (item) {
        return
      }
      state.carrierUnlocked.push(payload)
    },
    unlockReset: (state) => {
      state.carrierUnlocked = []
      state.valuesUnlocked = []
      state.valuesUnlockRemain = 3
      state.carrierUnlockRemain = 3
    }
  },
  actions: {
    showBarNav: (context) => {
      context.commit('showBarNav')
    },
    closeBarNav: (context) => {
      context.commit('closeBarNav')
    },
    showOverlay: (context) => {
      context.commit('showOverlay')
    },
    closeOverlay: (context) => {
      context.commit('closeOverlay')
    },
    closeMenu: (context) => {
      context.commit('closeMenu')
    },
    registerEmail: (context, payload) => {
      context.commit('registerEmail', payload)
    },
    universityYears: (context, payload) => {
      context.commit('universityYears', payload)
    },
    valuesUnlockRemain: (context, payload) => {
      context.commit('valuesUnlockRemain', payload)
    },
    valuesUnlocked: (context, payload) => {
      context.commit('valuesUnlocked', payload)
    },
    carrierUnlockRemain: (context, payload) => {
      context.commit('carrierUnlockRemain', payload)
    },
    carrierUnlocked: (context, payload) => {
      context.commit('carrierUnlocked', payload)
    }
  }
})
