import apolloClient from '@/includes/apollo'
import userRegister from '@/graphql/userRegister.mutation.graphql'
import userLogin from '@/graphql/userLogin.mutation.graphql'
import userSocialLogin from '@/graphql/userSocialLogin.mutation.graphql'
import userLogout from '@/graphql/userLogout.mutation.graphql'
import { useJwt } from '@/includes/jwt'

let timer

export default {
  state () {
    return {
      user: {},
      token: null,
      refreshToken: null,
      didAutoLogout: false
    }
  },
  getters: {
    user (state) {
      return state.user
    },
    token (state) {
      return state.token
    },
    isAuthenticated (state) {
      return !!state.token
    },
    isTokenAlive (state) {
      return !!state.token
    },
    didAutoLogout (state) {
      return state.didAutoLogout
    }
  },
  actions: {
    async setLogoutTime (context, payload) {
      const day = 86400000
      const expiresIn = payload - new Date().getTime() + (2 * day)
      if (expiresIn < 0) {
        return
      }
      timer = setTimeout(function () {
        context.dispatch('autoLogout')
      }, expiresIn)
    },
    async setStorage (context, payload) {
      const { decodedJwt } = useJwt(payload.accessToken)
      const expiresDate = +decodedJwt.exp * 1000
      await context.dispatch('setLogoutTime', expiresDate)
      localStorage.setItem('user', JSON.stringify(decodedJwt.user))
      localStorage.setItem('accessToken', payload.accessToken)
      localStorage.setItem('expires', expiresDate)
      localStorage.setItem('expiresFormatted', decodedJwt.expires)
      context.commit('login', payload)
    },
    async socialLogin (context, payload) {
      const { data } = await apolloClient.mutate({
        mutation: userSocialLogin,
        variables: payload
      })
      await context.dispatch('setStorage', data.socialLoginUser)
    },
    async login (context, payload) {
      const { data } = await apolloClient.mutate({
        mutation: userLogin,
        variables: payload
      })
      if (data.loginUser.message) {
        return data.loginUser
      }
      await context.dispatch('setStorage', data.loginUser)
    },
    async autoLogin (context) {
      const user = JSON.parse(localStorage.getItem('user'))
      const token = localStorage.getItem('accessToken')
      const expires = localStorage.getItem('expires')
      if (user && token) {
        context.commit('login', {
          user,
          token,
          expiresIn: null
        })
      }
      await context.dispatch('setLogoutTime', expires)
    },
    async register (context, payload) {
      const response = await apolloClient.mutate({
        mutation: userRegister,
        variables: payload
      })
      return response.data.registerUser
    },
    async logout (context, payload) {
      localStorage.removeItem('user')
      localStorage.removeItem('accessToken')
      localStorage.removeItem('expires')
      clearTimeout(timer)
      context.commit('login', {
        token: null,
        userId: null
      })
      await apolloClient.mutate({
        mutation: userLogout,
        variables: payload
      })
    },
    autoLogout (context) {
      const user = JSON.parse(localStorage.getItem('user'))
      context.dispatch('logout', { id: user.id, type: 'Auto Logout' })
      context.commit('setAutoLogout')
    },
    setUser (context, payload) {
      localStorage.removeItem('user')
      localStorage.setItem('user', JSON.stringify(payload))
      context.commit('setUser', payload)
    }
  },
  mutations: {
    login (state, payload) {
      state.token = payload.token
      state.user = payload.user
      state.didAutoLogout = false
    },
    setAutoLogout (state) {
      state.didAutoLogout = true
    },
    setUser (state, payload) {
      state.user = payload
    }
  },
  namespaced: true
}
