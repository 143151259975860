import { computed } from 'vue'
import { useStore } from 'vuex'
import { useMutation, useQuery } from '@vue/apollo-composable'
import addressQuery from '@/graphql/userAddresses.query.graphql'
import addressMutation from '@/graphql/userAddress.mutation.graphql'
import addressDeleteMutation from '@/graphql/userAddressDelete.mutation.graphql'
import addressDefaultMutation from '@/graphql/userAddressDefault.mutation.graphql'

export function useAddress () {
  const store = useStore()
  const { mutate: formSubmit, loading: isAddressSubmitting } = useMutation(addressMutation)
  const { mutate: formDeleteSubmit, loading: isDeleting } = useMutation(addressDeleteMutation)
  const { mutate: formDefaultSubmit, loading: isDefaultSubmitting } = useMutation(addressDefaultMutation)
  const { result: query, refetch: addressRefetch } = useQuery(addressQuery)
  const list = computed(() => query.value?.userAddresses ?? [])

  const addAddress = async (formValues, id = null) => {
    try {
      let message = formValues.addressTitle + ' adlı adres başarıyla eklendi.'
      if (id) {
        formValues.id = id
        message = formValues.addressTitle + ' adlı adres başarıyla güncellendi.'
      }
      await formSubmit(formValues)
      await addressRefetch()
      await successMessage(message)
    } catch (e) {
      await failMessage(e, 'Bir hata oluştu daha sonra tekrar deneyiniz.')
    }
  }

  const deleteAddress = async (address) => {
    try {
      await formDeleteSubmit({ id: address.id })
      await addressRefetch()
      await successMessage(address.addressTitle + ' adlı adres başarıyla silindi.')
    } catch (e) {
      await failMessage(e, 'Bir hata oluştu daha sonra tekrar deneyiniz.')
    }
  }

  const setDefaultAddress = async (address) => {
    try {
      await formDefaultSubmit({ id: address.id })
      await addressRefetch()
      await successMessage(address.addressTitle + ' adlı adres başarıyla varsayılan adres olarak ayarlandı.')
    } catch (e) {
      await failMessage(e, 'Bir hata oluştu daha sonra tekrar deneyiniz.')
    }
  }

  const successMessage = async (message) => {
    await store.dispatch('notification/addNotification', {
      type: 'success',
      icon: 'check',
      message
    }, { root: true })
  }

  const failMessage = async (e, message) => {
    await store.dispatch('notification/addNotification', {
      type: 'error',
      icon: 'error',
      message: e.message || message
    }, { root: true })
  }

  return {
    addAddress,
    deleteAddress,
    setDefaultAddress,
    list,
    isAddressSubmitting,
    isDeleting,
    isDefaultSubmitting
  }
}
