import apolloClient from '@/includes/apollo'
import productsQuery from '@/graphql/products.query.graphql'
import categoryQuery from '@/graphql/productCategories.query.graphql'
import productTableQuery from '@/graphql/productTable.query.graphql'

export default {
  state () {
    return {
      products: [],
      productTables: [],
      categories: []
    }
  },
  getters: {
    products: state => state.products,
    productById: (state) => (id) => {
      return state.products.find(product => product.id === id)
    },
    productBySlug: (state, getters) => (slug) => {
      const product = state.products.find(product => product.slug === slug)
      if (product) {
        let productTables = []
        if (product.comboItemIds.length) {
          productTables = state.productTables.filter(productTable => product.comboItemIds.find(item => productTable.id === parseInt(item)))
          // eslint-disable-next-line no-return-assign
          productTables.forEach(x => x.title = getters.productById(x.id.toString()).title)
        } else {
          productTables = [state.productTables.find(productTable => productTable.id === parseInt(product.id))]
        }
        const obj = { ...product }
        obj.productTables = productTables
        return obj
      }
    },
    productExceptBySlug: (state) => (slug) => {
      return state.products.filter(product => product.slug !== slug)
    },
    productTables: (state) => state.productTables,
    productTableById: (state, getters) => (id) => {
      const product = getters.productById(id)
      if (product.comboItemIds.length) {
        return state.productTables.filter(productTable => product.comboItemIds.find(item => productTable.id === parseInt(item.id)))
      }
      return state.productTables.find(productTable => productTable.id === id)
    },
    categories: (state, getters) => {
      const categories = JSON.parse(JSON.stringify(state.categories))
      return categories.map(el => {
        const obj = { ...el }
        obj.products = state.products.filter(item => item.categoryId === el.id)
        return obj
      })
    }
  },
  actions: {
    async products (context, payload) {
      const response = await apolloClient.query({ query: productsQuery })
        .catch((e) => {
          if (e.message === 'Invalid fingerprint') {
            localStorage.removeItem('user')
            localStorage.removeItem('accessToken')
            localStorage.removeItem('expires')
            localStorage.removeItem('expiresFormatted')
            window.location.reload()
          }
          console.warn(e.message)
        })
      context.commit('products', response.data.products)
    },
    async categories (context, payload) {
      const response = await apolloClient.query({ query: categoryQuery })
      context.commit('categories', response.data.productCategories)
    },
    async productTable (context, payload) {
      const response = await apolloClient.query({ query: productTableQuery, variables: payload })
      const columns = response.data.productTable.columns?.map((el) => JSON.parse(el))
      const rows = response.data.productTable.rows?.map((el) => JSON.parse(el))
      context.commit('addProductTable', { id: payload.id, columns, rows })
    }
  },
  mutations: {
    products (state, payload) {
      state.products = payload
    },
    categories (state, payload) {
      state.categories = payload
    },
    addProductTable (state, productTable) {
      state.productTables.push(productTable)
    }
  },
  namespaced: true
}
