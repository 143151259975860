export default {
  path: '/uye',
  name: 'Authentication',
  component: () => import(/* webpackChunkName: "auth" */ '@/views/Auth/AuthView'),
  redirect: { name: 'Login' },
  children: [
    {
      path: 'giris',
      name: 'Login',
      component: () => import(/* webpackChunkName: "auth" */ '@/views/Auth/LoginView')
    },
    {
      path: 'kayit',
      name: 'Register',
      component: () => import(/* webpackChunkName: "auth" */ '@/views/Auth/RegisterView')
    },
    {
      path: 'aktivasyon',
      name: 'ActivationInfo',
      component: () => import(/* webpackChunkName: "auth" */ '@/views/Auth/ActivationInfoView')
    },
    {
      path: 'aktivasyon/:token',
      name: 'Activation',
      component: () => import(/* webpackChunkName: "auth" */ '@/views/Auth/ActivationView')
    },
    {
      path: 'sifremi-unuttum',
      name: 'Password',
      component: () => import(/* webpackChunkName: "auth" */ '@/views/Auth/PasswordView')
    },
    {
      path: 'sifremi-unuttum/:token',
      name: 'PasswordReset',
      component: () => import(/* webpackChunkName: "auth" */ '@/views/Auth/PasswordResetView')
    }
  ]
}
