import { computed, reactive } from 'vue'
import { useQuery } from '@vue/apollo-composable'
import postQuery from '@/graphql/post.query.graphql'
import postFeaturedQuery from '@/graphql/postFeatured.query.graphql'
import postsQuery from '@/graphql/posts.query.graphql'
import postCategoriesQuery from '@/graphql/postCategories.query.graphql'

export function useBlog () {
  const postVariables = reactive({ slug: '' })
  const postOptions = reactive({ enabled: false })
  const { result: queryPost } = useQuery(postQuery, postVariables, postOptions)
  const { result: queryFeaturedPost } = useQuery(postFeaturedQuery)
  const { result: queryPosts } = useQuery(postsQuery)
  const { result: queryCategories } = useQuery(postCategoriesQuery)
  const post = computed(() => queryPost.value?.post ?? null)
  const featuredPost = computed(() => queryFeaturedPost.value?.postFeatured ?? null)
  const posts = computed(() => queryPosts.value?.posts ?? [])
  const postCategories = computed(() => queryCategories.value?.postCategories ?? [])

  const getPost = (slug) => {
    postVariables.slug = slug
    postOptions.enabled = true
  }

  return {
    getPost,
    post,
    posts,
    postCategories,
    featuredPost
  }
}
