import { useAddress } from '@/models/address'

export default {
  state () {
    return {
      addresses: []
    }
  },
  getters: {
    addresses: state => state.addresses,
    addressById: (state) => (id) => {
      return state.addresses.find(address => address.id === id)
    }
  },
  actions: {
    async addresses (context, payload) {
      const address = useAddress()
      const response = await address.list
      context.commit('addresses', response)
    }
  },
  mutations: {
    addresses (state, payload) {
      state.addresses = payload
    }
  },
  namespaced: true
}
