export default [
  {
    path: '/sepet',
    name: 'Cart',
    component: () => import(/* webpackChunkName: "cart" */ '@/views/Cart/CartView'),
    redirect: { name: 'CartList' },
    children: [
      {
        path: '',
        name: 'CartList',
        component: () => import(/* webpackChunkName: "cart" */ '@/views/Cart/ProductListView')
      },
      {
        path: '',
        name: 'AddressList',
        meta: { requiresAuth: true },
        component: () => import(/* webpackChunkName: "cart" */ '@/views/Cart/AddressListView')
      }
    ]
  },
  {
    path: '/odeme',
    name: 'PaymentForm',
    meta: { requiresAuth: true },
    component: () => import(/* webpackChunkName: "payment" */ '@/views/Cart/PaymentView')
  },
  {
    path: '/odeme/:token',
    name: 'PaymentResult',
    props: true,
    meta: { requiresAuth: true },
    component: () => import(/* webpackChunkName: "payment" */ '@/views/Cart/PaymentResultView')
  }
]
