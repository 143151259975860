export default {
  state () {
    return {
      notifications: []
    }
  },
  getters: {
    notifications: state => state.notifications
  },
  actions: {
    addNotification: (context, notification) => context.commit('addNotification', notification),
    removeNotification: (context, notification) => context.commit('removeNotification', notification)
  },
  mutations: {
    addNotification: (state, notification) => state.notifications.push({
      ...notification,
      id: (Math.random().toString(36) + Date.now().toString(36)).substr(2)
    }),
    removeNotification: (state, notification) => {
      const item = state.notifications.find(el => el.id === notification.id)
      if (item) {
        state.notifications = state.notifications.filter(el => el.id !== notification.id)
      }
    }
  },
  namespaced: true
}
