import apolloClient from '@/includes/apollo'
import userLogin from '@/graphql/userLogin.mutation.graphql'

export default {
  state () {
    return {
      cartItems: [],
      isCartOpen: false,
      addressId: 0,
      checkoutForm: {}
    }
  },
  getters: {
    isCartOpen: state => state.isCartOpen,
    cartItems: state => state.cartItems,
    checkoutForm: state => state.checkoutForm,
    cartTotal: state => {
      return state.cartItems.reduce((acc, cartItem) => {
        return (cartItem.quantity * (cartItem.promotion ? cartItem.promoPrice : cartItem.price)) + acc
      }, 0).toFixed(2)
    },
    cartQuantity: state => {
      return state.cartItems.reduce((acc, cartItem) => {
        return parseInt(cartItem.quantity) + acc
      }, 0)
    },
    addressId: state => state.addressId
  },
  actions: {
    async updateCart (context, payload) {
      const response = await apolloClient.mutate({
        mutation: userLogin,
        variables: payload
      })
      context.commit('updateCart', response.data.cart)
    },
    async getCart (context, payload) {
      const response = await apolloClient.query({
        query: userLogin,
        variables: payload
      })

      context.commit('updateCart', response.data.cart)
    }
  },
  mutations: {
    // eslint-disable-next-line no-return-assign
    checkoutForm: (state, form) => state.checkoutForm = form,
    // eslint-disable-next-line no-return-assign
    toggleCart: state => state.isCartOpen = !state.isCartOpen,
    // eslint-disable-next-line no-return-assign
    hideCart: state => state.isCartOpen = false,
    // eslint-disable-next-line no-return-assign
    updateAddressId: (state, addressId) => state.addressId = addressId,
    updateCartFromLocal: state => {
      const cart = localStorage.getItem('cart')
      if (cart) {
        state.cartItems = JSON.parse(cart)
      }
    },
    addCart (state, product) {
      const item = state.cartItems.find(el => el.id === product.id)
      if (item) {
        item.quantity += product.quantity
      } else {
        state.cartItems.push(product)
      }
      localStorage.setItem('cart', JSON.stringify(state.cartItems))
    },
    updateCart (state, product) {
      const item = state.cartItems.find(el => el.id === product.id)
      if (item) {
        item.quantity = product.quantity
      }
      localStorage.setItem('cart', JSON.stringify(state.cartItems))
    },
    removeCart (state, product) {
      const item = state.cartItems.find(el => el.id === product.id)
      if (item) {
        state.cartItems = state.cartItems.filter(el => el.id !== product.id)
      }
      localStorage.setItem('cart', JSON.stringify(state.cartItems))
    },
    resetCart (state) {
      state.cartItems = []
      localStorage.setItem('cart', JSON.stringify(state.cartItems))
    }
  },
  namespaced: true
}
